// libraries
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

// components
import { Container } from './styles/general';

class Navbar extends React.Component {
  render() {
    return <StaticQuery
      query={graphql`
        query FooterQuery {
          site {
            siteMetadata {
              email
              title
              phone
              facebookLink
            }
          }
        }
      `}
      render={data => (
        <footer className="footer">
          <Container className="lvl lvl1">
              <div>@2021 {data.site.siteMetadata.title}</div>
              <div>{data.site.siteMetadata.email}</div>
              <div>{data.site.siteMetadata.phone}</div>
              <div>
                <a href={data.site.siteMetadata.facebookLink}>
                  <span class="icon">
                    <i className="fab fa-facebook-f" />
                  </span>
                  Facebook
                </a>
              </div>
          </Container>
          <Container className="lvl lvl2" style={{maxWidth: "unset"}}>
              <div>a <a href="https://www.yetilabs.ca" target="_blank">yetilabs</a> project</div>
          </Container>
        </footer>
      )}
    />;
  }
}

export default Navbar;